<template>
  <div class="container mt-3">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item active">Kursus</li>
      </ol>
    </nav>
    <div class="p-3 bg-white mb-4" v-if="courseData != null">
      <div
        v-if="courseData.attributes.zoom_id === null || courseData.attributes.zoom_id === ''"
        class="video-container"
        v-html="courseData.attributes.embed_html"
      ></div>
      <h2 class="mb-0 mt-3 fw-bold">
        {{ courseData != null ? courseData.attributes.course_name : "" }}
      </h2>
      <h4 class="extra-small text-muted">{{
        getDate(courseData.attributes.createdAt)
      }}</h4>
      <p class="mt-3 fw-regular">
        {{ courseData != null ? courseData.attributes.description : "" }}
      </p>
      <button class="btn btn-primary" @click="openZoom()">Sertai Zoom</button>
    </div>
  </div>
</template>
<script>
import API from "./../../utils/API";
import moment from "moment";
// import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import axios from "axios";
import { mapGetters } from 'vuex'

export default {
  name: "ViewStreamPage",
  data() {
    return {
      courseData: null,
      client: null,
    };
  },
  watch: {
    async courseData() {
      if (this.courseData === null) return;

      console.log(this.courseData);

      if (this.courseData.attributes.zoom_id === null || this.courseData.attributes.zoom_id === '') 
        return;

      const res = await axios.post(
        "https://zoom-token-xi9wm.ondigitalocean.app",
        {
          meetingNumber: this.courseData.attributes.zoom_id,
          role: 0,
        }
      );

      console.log(res.data.signature);

      //document.getElementById("zmmtg-root").style.display = "block";

      this.ZoomMtg.init({
        leaveUrl: window.location.origin,
        success: (success) => {
          console.log(success);
          this.ZoomMtg.join({
            meetingNumber: this.courseData.attributes.zoom_id,            
            userName: this.currentUser ? this.currentUser.username : 'Public User',
            signature: res.data.signature,
            apiKey: "99bPbieBTEmtd87lzcDZFg",            
            passWord: this.courseData.attributes.zoom_password,            
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    getDate(date) {
      return date != undefined ? moment(date).format("LLLL") : "";
    },
    getProgram(id) {
      API.get(`courses/${id}?populate=%2A`).then((res) => {
        this.courseData = res.data.data;

        // ---- Open Zoom Just For Now -----
        window.open(`https://zoom.us/j/${this.courseData.attributes.zoom_id}?pwd=${this.courseData.attributes.zoom_password}`, '_blank')
      });
    },
    openZoom(){
      window.open(`https://zoom.us/j/${this.courseData.attributes.zoom_id}?pwd=${this.courseData.attributes.zoom_password}`, '_blank')
    }
  },

  created() {
    // ---- Comment Just For Now -----
    // this.ZoomMtg.setZoomJSLib("https://source.zoom.us/2.2.0/lib", "/av");
    // this.ZoomMtg.preLoadWasm();
    // this.ZoomMtg.prepareWebSDK();
    // this.ZoomMtg.i18n.load("en-US");
    // this.ZoomMtg.i18n.reload("en-US");
  },

  async mounted() {
    // ---- Comment Just For Now -----
    // this.ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
    //   console.log("inMeetingServiceListener onUserJoin", data);
    // });

    await this.getProgram(
      this.$route.params && this.$route.params.id ? this.$route.params.id : null
    );
  },
};
</script>
<style lang="scss" scoped>
@import url("https://source.zoom.us/2.2.0/css/bootstrap.css");
@import url("https://source.zoom.us/2.2.0/css/react-select.css");

.video-container {
  iframe {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 500px) {
    height: 200px;
  }

  @media (min-width: 500px) {
    height: 650px;
  }
}
</style>